export default function calcLum({r,g,b}) {
  r = (r/255) <= 0.03928 ? (r/255)/12.92 : Math.pow((((r/255)+0.055)/1.055),2.4);
  g = (g/255) <= 0.03928 ? (g/255)/12.92 : Math.pow((((g/255)+0.055)/1.055),2.4);
  b = (b/255) <= 0.03928 ? (b/255)/12.92 : Math.pow((((b/255)+0.055)/1.055),2.4);

  // r = (r/255)/12.92;
  // g = (g/255)/12.92;
  // b = (b/255)/12.92;

  // r = Math.pow((((r/255)+0.055)/1.055),2.4);
  // g = Math.pow((((g/255)+0.055)/1.055),2.4);
  // b = Math.pow((((b/255)+0.055)/1.055),2.4);

  // r /= 255;
  // g /= 255;
  // b /= 255;
  return Math.round((0.2126 * r + 0.7152 * g + 0.0722 * b) * 1000) / 1000;
}
